import React, { useState, useEffect } from 'react'
import '../App.css';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { ImNotification } from "react-icons/im";
import Loa from "../asset/images/loa.gif";
// import Tickets from "../asset/images/tickets.webp"
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import Lightlottery from "../asset/images/lightlottery.gif";
import Confetti from 'react-confetti'
import Pin from "../asset/images/BANI.png";
import { PiDotsThreeCircle } from "react-icons/pi";
import { MdCancel } from "react-icons/md";
import Goldentoken from "../asset/images/goldentoken.png";
import Bitcointoken from "../asset/images/Bitcointoken.png";
import BNBtokens from "../asset/images/BNBtokens.png"
// import Bani from "../asset/images/BANI.png"
// import { FaLock } from "react-icons/fa";
import { CiLock } from "react-icons/ci";
import { makeApiRequest } from '../services/commonAxiosService';
import { param } from 'jquery';
import { ToastContainer, toast } from "react-toastify";
function Jackpotpage({ data }) {
  const [timer, setTimer] = useState({})



  

  let userChatId;
  if (data) {
    userChatId = data;
  } else {
    userChatId = localStorage.getItem("userId");
  }
  const [activeDay, setActiveDay] = useState(1);
  const [userData, setUserData] = useState({})

  useEffect(() => {
    getTaskStatus();
  }, [])
 
  const showToast = () => {
    toast.info(
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <ImNotification className="fs-4" style={{ color: 'blue', marginRight: '10px' }} /> */}
        <span style={{ color: 'grey' }}>Claim your daily rewards twice a day – available at 12:00 UTC!</span>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        style: { backgroundColor: 'white', color: 'black' }, // Custom background and text color
      }
    );
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      const expiryTime = () => {
        try {
          getTaskStatus()
          const endDate = new Date(userData.userEndDate);

          const now = new Date();
          const remainingMs = endDate - now;

          const countdownSeconds = Math.floor(remainingMs / 1000);
          const hours = Math.floor(countdownSeconds / 3600);
          const minutes = Math.floor((countdownSeconds % 3600) / 60);
          const seconds = countdownSeconds % 60;

          const countdown = {
            hours: Math.max(hours, 0),
            minutes: Math.max(minutes, 0),
            seconds: Math.max(seconds, 0),
          };

          setTimer(countdown);
          if (remainingMs <= 0) clearInterval(intervalId);

        } catch (error) {
          console.error("Error calculating expiry time:", error);
          clearInterval(intervalId);
        }
      };

      expiryTime();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [userData]);

  const getTaskStatus = async () => {
    try {
      let payload = {
        "userChatId": userChatId
      }
      let params = {
        url: "get-user-data",
        method: "post",
        data: payload
      }
      let res = await makeApiRequest(params)
      if (res.status) {
        setUserData(res.data)
      }
    } catch (error) {
      console.log(error, "getTaskStatus error")
    }
  }


   
  const handleClick = async (day, Amt) => {
    try {

      console.log(userData.dailyTaskStatus, day, "getTaskStatus");
      if (userData.dailyTaskStatus == day) {
        return toast.error("You have already claimed your reward", { theme: "dark" });
      }
      let payLoad = {
        chatId: userChatId,
        dayCount: day,
        taskReward: Amt
      }
      let params = {
        url: "post-daily-task",
        method: 'post',
        data: payLoad
      }
      let res = await makeApiRequest(params);
      console.log("res", res);
      if (res.status) {
        toast.success(res.message, { theme: "dark" });
        window.open('https://play.google.com/store/apps/details?id=com.kerdos.wallet&hl=en', "_blank")
        getTaskStatus();
      } else {
        toast.error(res.message, { theme: "dark" });
      }
    } catch (error) {
      toast.error("Something went wrong", { theme: "dark" });
    }
    setActiveDay(day);
  };
  return (

    // <div className='whl-jackpot min-vh-100 position-relative goat-full-home-01'>
    //   <div className='container'>

    //     <div className='row px-0'>
    //       <div className='col-12'>
    //         <div className='d-flex justify-content-between align-items-center py-2'>
    //           <Link to="/">
    //           <AiOutlineArrowLeft className='text-white fs-4' />
    //           </Link>
    //           <p className='mb-auto  text-white'>Lucky lottery</p>
    //           <ImNotification className='text-white fs-4' />
    //         </div>
    //       </div>
    //     </div>

    //     <div className='row py-3'>
    //       <div className='col-lg-12'>
    //         <div className='position-relative'>
    //           <img src={Lightlottery} className='goat-light-lottery' />
    //           <div className='lottery-tokens-01 d-flex align-items-center justify-content-between'>
    //             <span className='alter-jackpot fw-bold text-muted'>JACKPOT</span>
    //             <span className='fw-bold alter-points-tokens'>41.01 $TON</span>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     <div className='row px-0'>
    //       <div className='my-2 bg-primary'>
    //         <p className='py-3'>jackpot</p>
    //       </div>

    //     </div>

    //     <div className='total-jackpot-box'>
    //       <div className='row mt-4 whole-jackpot-box new-lucky-lottery px-0'>
    //         <div className='col-lg-12 mx-auto'>
    //           <div className='bg-dark last-one-ctn mt-4 d-flex justify-content-between align-items-center p-3'>
    //             <div className='jackpot-rounded-top'>
    //               <p className='mb-auto'>
    //                 <input type="text" className='jackpot-first-box jackpot-input-boxex' placeholder='' />
    //               </p>
    //               <p className='   mb-auto'>
    //                 <input type="text" className='jackpot-second-box first py-2 mt-2' placeholder='' />
    //               </p>
    //             </div>
    //             <div className='jackpot-rounded'>

    //               <p className='mb-auto mt-4 first'>
    //                 <input type="text" className='jackpot-first-box-01 jackpot-input-boxex ' placeholder='' />
    //               </p>

    //               <p className=''>
    //                 <input type="text" className='jackpot-first-box-01 py-2 jackpot-input-boxex mt-1' placeholder="" />
    //               </p>
    //             </div>

    //           </div>
    //         </div>
    //       </div>
    //       <div className='row mt-4 whole-jackpot-box new-lucky-lottery px-0'>
    //         <div className='col-lg-12 mx-auto'>
    //           <div className='bg-dark last-one-ctn mt-4 d-flex justify-content-between align-items-center p-3'>
    //             <div className='jackpot-rounded-top'>
    //               <p className='mb-auto'>
    //                 <input type="text" className='jackpot-first-box jackpot-input-boxex' placeholder='' />
    //               </p>
    //               <p className='mb-auto'>
    //                 <input type="text" className='jackpot-second-box first py-2 mt-2' placeholder='' />
    //               </p>
    //             </div>
    //             <div className='jackpot-rounded'>

    //               <p className='mb-auto mt-4 first'>
    //                 <input type="text" className='jackpot-first-box-01 jackpot-input-boxex ' placeholder='' />
    //               </p>

    //               <p className=''>
    //                 <input type="text" className='jackpot-first-box-01 py-2 jackpot-input-boxex mt-1' placeholder="" />
    //               </p>
    //             </div>

    //           </div>
    //         </div>
    //       </div>
    //       <div className='row mt-4 whole-jackpot-box new-lucky-lottery px-0'>
    //         <div className='col-lg-12 mx-auto'>
    //           <div className='bg-dark last-one-ctn mt-4 d-flex justify-content-between align-items-center p-3'>
    //             <div className='jackpot-rounded-top'>
    //               <p className='mb-auto'>
    //                 <input type="text" className='jackpot-first-box jackpot-input-boxex' placeholder='' />
    //               </p>
    //               <p className='   mb-auto'>
    //                 <input type="text" className='jackpot-second-box first py-2 mt-2' placeholder='' />
    //               </p>
    //             </div>
    //             <div className='jackpot-rounded'>

    //               <p className='mb-auto mt-4 first'>
    //                 <input type="text" className='jackpot-first-box-01 jackpot-input-boxex ' placeholder='' />
    //               </p>

    //               <p className=''>
    //                 <input type="text" className='jackpot-first-box-01 py-2 jackpot-input-boxex mt-1' placeholder="" />
    //               </p>
    //             </div>

    //           </div>
    //         </div>
    //       </div>

    //     </div>


    //     <div className='row jackpot-numbers'>
    //       <div className=' px-2 bg-dark py-3  mt-0 d-flex justify-content-between align-items-center previous-winner-boxex align-items-center mt-2'>
    //         <p type="button" class="mb-0" data-bs-toggle="modal" data-bs-target="#exampleModal">
    //           <img src={Loa} className='loa-gif-img mb-auto' data-bs-toggle="modal"  data-bs-target="#exampleModal"/><span className='fw-bold text-white'  data-bs-toggle="modal"  data-bs-target="#exampleModal">Previous winner</span>
    //         </p>

    //         <div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    //           <div class="modal-dialog modal-dialog-bottom text-center ">

    //             <div class="modal-content">
    //             <Confetti className='paper'/>
    //               <div class="modal-header">
    //                 <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
    //                 <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    //               </div>
    //               <div class="modal-body">
    //                 <p className='jackpot-us-dollars text-white'>US</p>
    //                 <h4 className='jackpot-dollars-ctn fw-bold my-3 text-white'>Congratulation sajjad2292 has won the last Jackpot of total:</h4>
    //                 <h1 className='jackpot-dollars-pointind fs-5 my-3 text-white'>402.232 $TON</h1>
    //                 <p className='my-3 text-white'>Time till next jackpot:</p>
    //                 <div className='my-3'>
    //                   <button className='me-1 goats-timer-sec text-white'><span>11<br/>hours</span></button>
    //                   <button className='me-1 goats-timer-sec'><span>52<br/>mins</span></button>
    //                   <button className='goats-timer-sec'>
    //   <span className='goats-timing'>{seconds}<br />secs</span>
    // </button>
    //                 </div>
    //                 <button className='bg-primary w-100 py-3 text-white border-0 models-go-back-btn fw-bold'>Go back</button>
    //               </div>

    //             </div>
    //           </div>
    //         </div>

    //         <Link to="/previousnumber">
    //           <button className='get-more-buttons fw-bold'>Get more <img className='jackpot-tickets' src={Tickets} /></button>
    //         </Link>

    //       </div>




    //       <div className='col-lg-12 py-3'>

    //         <div className='text-center'>
    //           <p className='text-center lucky-lottery-01'>Lucky number</p>
    //           <ul className='d-flex justify-content-center align-items-center px-0'>
    //             <li className='jackpot-tokens d-flex justify-content-center align-items-center fw-bold me-1'>8</li>
    //             <li className='jackpot-tokens d-flex justify-content-center align-items-center fw-bold me-1'>4</li>
    //             <li className='jackpot-tokens d-flex justify-content-center align-items-center fw-bold  me-1'>3</li>
    //             <li className='jackpot-tokens d-flex justify-content-center align-items-center fw-bold me-1'>1</li>
    //             <li className='jackpot-tokens d-flex justify-content-center align-items-center fw-bold me-1'>9</li>
    //             <li className='jackpot-tokens d-flex justify-content-center align-items-center fw-bold'>10</li>
    //           </ul>
    //         </div>
    //       </div>
    //     </div>

    //   </div>

    //   <div className='get-tickets text-center py-2'>
    //   <p className='fw-bold text-white fw-400 lucky-lottery-01 '>you have no tickets !</p>
    //   <button className='px-4 py-2 bg-danger text-white fw-bold get-tickets-btn-01'>Get tickets <img className='jackpot-tickets' src={Tickets} /></button>
    //   </div>

    //   <div className='goats-get-ticket'>
    //     <p className='fw-bold text-white'>you have no tickets</p>
    //     <button className='new-token-tickets-01'>Get Ticket<img className='jackpot-tickets' src={Tickets} /></button>
    //   </div>


    // </div>
    <div className='min-vh-100 goat-full-box-02 friends-zone jackpot-new-modals-sec position-relative'>
      {/* <ToastContainer /> */}
      <div className='container jackpot-modal-container'>
        <div className='row'>
          <div className='col-12'>
            <div>
              <div className='d-flex justify-content-between bani-close-items align-items-center py-2'>
                {/* <span className='text-primary ps-2' style={{ fontSize: "20px" }}>Close</span>
                <span className='text-white text-center ' style={{ fontSize: "18px" }}><b>$BANI</b><br /><span className='mt-0 pt-0 text-muted' style={{ fontSize: "16px" }}>mini app</span></span>
                <span className='text-primary pe-2'><PiDotsThreeCircle data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ fontSize: "33px" }} /></span> */}
              </div>
              <div className='d-flex justify-content-between   align-items-center py-3'>
               
                <span className='text-primary ps-2' style={{ fontSize: "20px" }}>
                  <Link to="/">
                    <AiOutlineArrowLeft className='text-white fs-4' />
                  </Link>
                </span>
                <span className='text-primary pe-2 text-center'>
        <ImNotification 
          className='text-white  fs-4 jackpot-page-notification' 
          onClick={showToast} 
          style={{ cursor: 'pointer'}} 
        />
             </span>
             <ToastContainer />
                <div>
                <div>
    
    </div>
                </div>
           
              </div>
              <div>

              </div>
              <div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <==============> */}
      <div className='p-2 points-section'>
        <p className='text-white fw-bold text-center total-claimed'>Total Claimed</p>
        <h5 className=' text-center fw-bold my-2 fw-bold total-claimed-points'> {userData.dailyClaim || 0} </h5>
        {/* <label className='mb-2 fw-bold text-white text-center'></label> */}
        {/*       
        <input type="text" placeholder="Total amount" className='w-100 p-2 total-claimed-amt'/> */}
      </div>
      {/* <=====================> */}


      {/* <=====================> */}
      <div className='container '>
        <div className='row d-flex justify-content-center align-items-center'>

          <div className='col-8  pe-0  day-one-bani'>
            <div className=''>
              <a onClick={() => handleClick(1, 500)}>
                <button
                  className={`w-100 border-0 fw-bold text-white top-first-left-btn ${activeDay === 1 ? 'active-btn' : ''}`}

                >
                  Daily Rewards
                </button>
                <div className={`py-3 text-center box-def-01  ${activeDay === 1 ? 'active-image' : ''}`}>

                  <a></a>  <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  {
                    userData && userData.dailyTaskStatus == 1 ? (
                      <p className=' mb-auto mt-3 table-bin-points-001 py-1'>Completed</p>
                    ) : (
                      <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                    )
                  }
                </div>
              </a>

            </div>
          </div>







          {/* <div className='row'>
            <div className='col-6 pe-0'>
              <div className=''>
                <a onClick={() => handleClick(1, 500)}>
                  <button
                    className={`w-100 border-0 fw-bold text-white top-first-left-btn ${activeDay === 1 ? 'active-btn' : ''}`}

                  >
                    Day 1
                  </button>
                  <div className={`py-3 text-center box-def-01  ${activeDay === 1 ? 'active-image' : ''}`}>
                    
                    <a></a>  <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                    {
                      userData && userData.dailyTaskStatus == 1 ? (
                        <p className=' mb-auto mt-3 table-bin-points-001 py-1'>Completed</p>
                      ) : (
                        <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                      )
                    }
                  </div>
                </a>

              </div>
            </div>

            <div className='col-6 ps-0' style={{ borderLeft: '1px solid black' }}>
              <div className=''>
                <button
                  className={`w-100 border-0 fw-bold text-white top-first-right-btn `}
               
                >
                  Day 2
                </button>
                <div className={`text-center box-def-01 py-3 position-relative `}>
                 
                  <div className='locker-image'>
                    <p><CiLock className="text-white" style={{ fontSize: "70px" }} /></p>
                  </div>
                  <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-6 pe-0'>
              <div className='bg-dark'>
                <button
                  className={`w-100 border-0 fw-bold text-white new-bin-box-btn `}
                
                >
                  Day 3
                </button>
                <div className={`text-center box-def-01 py-3 position-relative `}>
                
                  <div className='locker-image'>
                    <p><CiLock className="text-white" style={{ fontSize: "70px" }} /></p>
                  </div>
                  <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
              </div>
            </div>
            <div className='col-6 ps-0' style={{ borderLeft: "1px solid black" }}>
              <div className='bg-dark'>
                <button
                  className={`w-100 border-0 fw-bold text-white new-bin-box-btn `}
               
                >
                  Day 4
                </button>
                <div className={`text-center box-def-01 py-3 position-relative `}>
                 
                  <div className='locker-image'>
                    <p><CiLock className="text-white" style={{ fontSize: "70px" }} /></p>
                  </div>
                  <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
              </div>
            </div>


          </div>
          <div className='row'>
            <div className='col-6 pe-0'>
              <div className='bg-dark'>
                <button
                  className={`w-100 border-0 fw-bold text-white new-bin-box-btn `}
            
                >
                  Day 5
                </button>
                <div className={`text-center box-def-01 py-3 position-relative `}>
                  
                  <div className='locker-image'>
                    <p><CiLock className="text-white" style={{ fontSize: "70px" }} /></p>
                  </div>
                  <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
              </div>
            </div>
            <div className='col-6 ps-0' style={{ borderLeft: "1px solid black" }}>
              <div className='bg-dark'>
                <button
                  className={`w-100 border-0 fw-bold text-white new-bin-box-btn`}
               
                >
                  Day 6
                </button>
                <div className={`text-center box-def-01 py-3 position-relative `}>
                 
                  <div className='locker-image'>
                    <p><CiLock className="text-white" style={{ fontSize: "70px" }} /></p>
                  </div>
                  <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
              </div>
            </div>


          </div>

          <div className='row'>
            <div className='col-12'>
              <div>
                <button
                  className={`w-100 border-0 fw-bold text-white new-bin-box-btn position-relative`}
              
                >
                  Day 7
                </button>
                <div className={`text-center box-def-01 py-3 position-relative`} style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                
                  <div className='locker-image'>
                    <p><CiLock className="text-white" style={{ fontSize: "70px" }} /></p>
                  </div>
                  <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
                <div>
                </div>
              </div>
            </div>
          </div> */}
        </div>



      </div>

      {(timer.hours || timer.minutes || timer.seconds) ? (
        <div className="timer text-white text-center my-3 ps-3 d-flex justify-content-center">
          <p className="timer-button jackpot-rewards-hours me-1">{timer.hours || '00'}<br /><span className='jackpot-rewards-hours-01 '>Hours</span></p>
          <p className="timer-button jackpot-rewards-hours me-1">{timer.minutes || '00'}<br /><span className='jackpot-rewards-hours-01 '>Minutes</span></p>
          <p className="timer-button jackpot-rewards-hours">{timer.seconds || '00'}<br /><span className='jackpot-rewards-hours-01'>Seconds</span></p>
        </div>
      ) : (
        <></>
      )}
    </div>


  )
}

export default Jackpotpage
{/* <img src={Pin} style={{ width: "50px", height: "50px" }} /> */ }
// new-bin-box-btn

// <===============>
// 1205,1225
// <===========>
// function NotificationComponent() {
//   return (
//     <div>
//       <ImNotification 
//         className='text-white fs-4'
//         onClick={handleNotificationClick}
//         style={{ cursor: 'pointer' }}
//       />
//       <ToastContainer />
//     </div>
//   );
// }
     {/* <ImNotification className='text-white fs-4'/> */}
                {/* <ToastContainer className="text-center"/>
                <span className='text-primary pe-2'>
                  <ImNotification className='text-white fs-4'  />
                </span>
                <ToastContainer /> */}


