import React, { useEffect, useState } from "react";
import Gameimg from "../asset/images/game-post.webp";
import Golddollar from "../asset/images/gold-dollar.gif";
import Silver from "../asset/images/silver-img.gif";
import Bronze from "../asset/images/bronze-img.gif";
import { makeApiRequest } from "../services/commonAxiosService";
import Banis from "../asset/images/BANI.png"

function Navtabs() {
  let userId =
    localStorage.getItem("userId") != undefined
      ? localStorage.getItem("userId")
      : "";
  const [userData, setUserData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loader, setLoader] = useState(false);
  const [userCounts, setUserCounts] = useState(0)
  useEffect(() => {
    getLeaderBoard();
  }, []);
  const getLeaderBoard = async () => {
    try {
      setLoader(true);
      let payLoad = {
        chatId: userId,
      };
      let params = {
        url: "users-rank",
        method: "post",
        data: payLoad,
      };
      let response = await makeApiRequest(params);
      if (response.status) {
        setUserCounts(response.userCount)
        setUserData(response.rankData);
        setSelectedItem(response.userRankData);
        setLoader(false);
      }
    } catch (error) {
      setLoader(true);
    }
  };
  let firstRank = userData[0];
  let secondRank = userData[1];
  let thirdRank = userData[2];
  return (
    <div className="goat-ranking-whl">

      <h5 className="Righteous-font"> {userCounts} Holders </h5>
      <ul
        class="nav nav-pills mb-3 py-2 goat-ranking-nav-tabs d-felx align-items-center justify-content-around"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active fw-bold text-white wall-of-fame-01"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            Wall of fame
          </button>
        </li>

        {/* <li class="nav-item" role="presentation">
                    <button class="nav-link fw-bold text-white" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" style={{ color: "grey" }}>mini game</button>
                </li> */}
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div className="">
            {
              loader == true ? (
                <>
                  <div class=" position-absolute bani-loader-ctns-01" role="status">
                    <span class="visually-hidden">Loading...</span>
                    <img src={Banis} className="bani-loader-img" />
                    <p className="mt-4 ms-1 text-white" style={{ fontSize: "15px" }}>
                      <span class="dot text-white me-1">12</span>
                      <span class="dot me-1">12</span>
                      <span class="dot me-1">12</span>
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="">
                    {selectedItem ? (
                      <div className="">
                        <div className="d-flex bg-primary goat-codemychain-ctn my-4 py-3">
                          <span className="text-center" style={{ width: "100px" }}>
                            <img
                              alt=""
                              style={{ width: "50px" }}
                              src="https://dev.goatsbot.xyz/icons/face.gif?w=3840&amp;q=75"
                            />
                          </span>
                          <span>
                            <h2 className="fw-bold text-white goat-total-new-heading">
                              {selectedItem.username ? selectedItem.username : selectedItem.first_name}
                            </h2>
                            <span
                              className="fw-bold goat-total-sub-heading"
                              style={{ fontSize: "20px", color: "rgb(142 156 169)" }}
                            >
                              {selectedItem.balance + " $BANI" + ' , ' + "#" + selectedItem.leaderBoard}
                            </span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="my-3 goat-ranking-box-1">
                      {firstRank ? (
                        <div
                          className={`${"d-flex align-items-center py-3 justify-content-between"} ${firstRank.isHighlighted ? "ranking-grade-points" : ""
                            }`}
                        >
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="initials-fallback-01 ms-2">
                                {firstRank.username
                                  ? firstRank.username.charAt(0).toUpperCase()
                                  : firstRank.first_name.charAt(0).toUpperCase()}
                              </div>
                            </div>
                            <p className="mb-0">
                              <span className="fw-bold goat-title-name goat-total-new-heading">
                                {firstRank.username ? firstRank.username : firstRank.first_name}
                              </span>
                              <br />
                              <span
                                className="fw-bold py-2 total-small-size-ctn"
                                style={{ color: "#686D72" }}
                              >
                                {firstRank.balance} $BANI
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className="mb-auto">
                              <img
                                alt=""
                                style={{ width: "50px" }}
                                src={Golddollar}
                              />
                            </p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {secondRank ? (
                        <div
                          className={`${"d-flex align-items-center py-3 justify-content-between"} ${secondRank.isHighlighted ? "ranking-grade-points" : ""
                            }`}
                        >
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="initials-fallback-02 ms-2">
                                {secondRank.username
                                  ? secondRank.username.charAt(0).toUpperCase()
                                  : secondRank.first_name.charAt(0).toUpperCase()}
                              </div>
                            </div>
                            <p className="mb-0">
                              <span className="fw-bold goat-title-name goat-total-new-heading">
                                {secondRank.username ? secondRank.username : secondRank.first_name}
                              </span>
                              <br />
                              <span
                                className="fw-bold py-2 total-small-size-ctn"
                                style={{ color: "#686D72" }}
                              >
                                {secondRank.balance} $BANI
                              </span>
                              <br />
                            </p>
                          </div>
                          <div>
                            <p className="mb-auto">
                              <img alt="" style={{ width: "50px" }} src={Silver} />
                            </p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {thirdRank ? (
                        <div
                          className={`${"d-flex align-items-center py-3 justify-content-between"} ${thirdRank.isHighlighted ? "ranking-grade-points" : ""
                            }`}
                        >
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="initials-fallback-03 ms-2">
                                {thirdRank.username
                                  ? thirdRank.username.charAt(0).toUpperCase()
                                  : thirdRank.first_name.charAt(0).toUpperCase()}
                              </div>
                            </div>
                            <p className="mb-0">
                              <span className="fw-bold goat-title-name goat-total-new-heading">
                                {thirdRank.username ? thirdRank.username : thirdRank.first_name}
                              </span>
                              <br />
                              <span
                                className=" py-2 fw-bold total-small-size-ctn"
                                style={{ color: "#686D72" }}
                              >
                                {thirdRank.balance} $BANI
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className="mb-auto">
                              <img alt="" style={{ width: "50px" }} src={Bronze} />
                            </p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {userData.length ? (
                        userData.slice(3).map((data) => {
                          const initial = data.username
                            ? data.username.charAt(0).toUpperCase()
                            : data.first_name.charAt(0).toUpperCase();
                          return (
                            <>
                              <div
                                className={`${"d-flex align-items-center py-3 justify-content-between"} ${data.isHighlighted ? "ranking-copper-medal" : ""
                                  }`}
                              >
                                <div className="d-flex align-items-center">
                                  <div>
                                    <div className="initials-fallback ms-2">{initial}</div>
                                  </div>
                                  <p className="mb-0">
                                    <span className="fw-bold goat-title-name goat-total-new-heading">
                                      {data.username ? data.username : data.first_name}
                                    </span>
                                    <br />
                                    <span
                                      className="fw-bold py-2 total-small-size-ctn"
                                      style={{ color: "#686D72" }}
                                    >
                                      {data.balance} $BANI
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className="mb-auto fw-bold goat-new-numbers">
                                    {"#" + data.leaderBoard}
                                  </p>
                                </div>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>

                  </div>
                </>
              )
            }



          </div>
        </div>
        {/* <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className=''>
                        <div className=''>
                            <div className=''>
                                <p className=" text-muted text-center goat-total-new-heading fw-bold my-5" style={{ fontSize: "19px" }}>Top 10 $BANI earners through our mini games will be rewarded. Resets daily.</p>
                                <div className='d-flex justify-content-between mt-5 mb-2'>
                                    <span className='text-muted goat-total-sub-heading fw-bold' style={{ fontSize: "19px" }}><b>Today</b>
                                        / 07 Sep, 2024</span>
                                    <span className='text-muted goat-total-sub-heading fw-bold' style={{ fontSize: "19px" }}>
                                        114,335 players</span>
                                </div>
                                <div className='d-flex bg-primary goat-codemychain-ctn my-4 py-3'>
                                    <span className="text-center" style={{ width: "100px" }}><img alt="" style={{ width: "50px" }} src="https://dev.goatsbot.xyz/icons/face.gif?w=3840&amp;q=75" /></span>
                                    <span><h2 className='fw-bold text-white goat-total-new-heading'>
                                        CODEMYCHAIN</h2><span className='text-muted fw-bold goat-total-sub-heading' style={{ fontSize: "20px" }}>0 $BANI</span></span>

                                </div>

                            </div>
                            <div className='my-3 goat-ranking-box-1'>
                                <div className=' d-flex align-items-center py-3 justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <p className='goat-us-dollars mb-0'><img className='diamong-game' src={Gameimg}/></p>
                                        <p className='mb-auto'><span className='fw-bold goat-title-name goat-total-new-heading'>Uzairmirza30973</span><br /><span className='text-muted py-2 goat-total-sub-heading'>177,396,481 $BANI</span><br />
                                            <span className='text-muted goat-total-sub-heading'>Reward: 5 $TON</span></p>
                                    </div>
                                    <div>
                                        <p className='mb-auto'>
                                            <img alt="" style={{ width: "50px" }} src={Golddollar} />
                                        </p>
                                    </div>
                                </div>
                                <div className=' d-flex align-items-center py-3 justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                    <p className='goat-us-dollars mb-0'><img className='diamong-game' src={Gameimg}/></p>
                                        <p className='mb-auto'><span className='fw-bold goat-title-name goat-total-new-heading'>PhanDatNe</span><br /><span className='text-muted py-2 goat-total-sub-heading'>177,396,481 $BANI</span><br />
                                            <span className='text-muted goat-total-sub-heading'>Reward: 5 $TON</span></p>
                                    </div>
                                    <div>
                                        <p className='mb-auto'>
                                            <img alt="" style={{ width: "50px" }} src={Silver} />
                                        </p>
                                    </div>
                                </div>
                                <div className=' d-flex align-items-center py-3 justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                    <p className='goat-us-dollars mb-0'><img className='diamong-game' src={Gameimg}/></p>
                                        <p className='mb-auto'><span className='fw-bold goat-title-name goat-total-new-heading'>Ad_ProTrader365</span><br /><span className='text-muted py-2 goat-total-sub-heading'>230,642,649 $BANI
                                        </span><br />
                                            <span className='text-muted goat-total-sub-heading'>Reward: 2 $TON</span></p>
                                    </div>
                                    <div>
                                        <p className='mb-auto'>
                                            <img alt="" style={{ width: "50px" }} src={Bronze} />
                                        </p>
                                    </div>
                                </div>
                                <div className=' d-flex align-items-center py-3 justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                    <p className='goat-us-dollars mb-0'><img className='diamong-game' src={Gameimg}/></p>
                                        <p className='mb-auto'><span className='fw-bold goat-title-name goat-total-new-heading'>Pgkum</span><br /><span className='text-muted py-2 goat-total-sub-heading'>216,274,160 $BANI</span><br />
                                            <span className='text-muted goat-total-sub-heading'>Reward: 1 $TON</span></p>
                                    </div>
                                    <div>
                                        <p className='mb-auto fw-bold goat-new-numbers'>
                                            #4
                                        </p>
                                    </div>
                                </div>
                                <div className=' d-flex align-items-center py-3 justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                    <p className='goat-us-dollars mb-0'><img className='diamong-game' src={Gameimg}/></p>
                                        <p className='mb-auto'><span className='fw-bold goat-title-name goat-total-new-heading'>7453483845</span><br /><span className='text-muted goat-total-sub-heading py-2'>177,396,481 $BANI</span><br />
                                            <span className='text-muted goat-total-sub-heading'>Reward: 5 $TON</span></p>
                                    </div>
                                    <div>
                                        <p className='mb-auto fw-bold goat-new-numbers'>
                                            #5
                                        </p>
                                    </div>
                                </div>
                                <div className=' d-flex align-items-center py-3 justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                    <p className='goat-us-dollars mb-0'><img className='diamong-game' src={Gameimg}/></p>
                                        <p className='mb-auto'><span className='fw-bold goat-title-name goat-total-new-heading'>US
                                            Uzairmirza30973</span><br /><span className='text-muted py-2 goat-total-sub-heading'>177,396,481 $BANI</span><br />
                                            <span className='text-muted goat-total-sub-heading'>Reward: 5 $TON</span></p>
                                    </div>
                                    <div>
                                        <p className='mb-auto fw-bold goat-new-numbers'>
                                            #6
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between py-2'>
                            <p><span className='fw-bold goat-timing-ctn'>Yesterday</span>
                                / 06 Sep, 2024</p>
                            <p className='fw-bold goat-timing-ctn'>
                                339,974 players</p>
                        </div>
                        <div className='d-flex bg-primary goat-codemychain-ctn my-3 py-3'>
                            <span className="text-center" style={{ width: "100px" }}><img alt="" style={{ width: "50px" }} src="https://dev.goatsbot.xyz/icons/face.gif?w=3840&amp;q=75" /></span>
                            <span><h2 className='fw-bold text-white goat-total-new-heading'>
                                CODEMYCHAIN</h2><span className='text-muted fw-bold goat-total-sub-heading'>0 $BANI</span></span>

                        </div>
                        <div className='my-3 goat-ranking-box-1'>
                            <div className=' d-flex align-items-center py-3 justify-content-between'>
                                <div className='d-flex align-items-center'>
                                <p className='goat-us-dollars mb-0'><img className='diamong-game' src={Gameimg}/></p>
                                    <p className='mb-auto'><span className='fw-bold goat-title-name goat-total-new-heading'>Uzairmirza30973</span><br /><span className='text-muted py-2 goat-total-sub-heading'>177,396,481 $BANI</span><br />
                                        <span className='text-muted goat-total-sub-heading'>Reward: 5 $TON</span></p>
                                </div>
                                <div>
                                    <p className='mb-auto'>
                                        <img alt="" style={{ width: "50px" }} src={Golddollar} />
                                    </p>
                                </div>
                            </div>
                            <div className=' d-flex align-items-center py-3 justify-content-between'>
                                <div className='d-flex align-items-center'>
                                <p className='goat-us-dollars mb-0'><img className='diamong-game' src={Gameimg}/></p>
                                    <p className='mb-auto'><span className='fw-bold goat-title-name goat-total-new-heading'>PhanDatNe</span><br /><span className='text-muted py-2 goat-total-sub-heading'>177,396,481 $BANI</span><br />
                                        <span className='text-muted goat-total-sub-heading'>Reward: 5 $TON</span></p>
                                </div>
                                <div>
                                    <p className='mb-auto'>
                                        <img alt="" style={{ width: "50px" }} src={Silver} />
                                    </p>
                                </div>
                            </div>
                            <div className=' d-flex align-items-center py-3 justify-content-between'>
                                <div className='d-flex align-items-center'>
                                <p className='goat-us-dollars mb-0'><img className='diamong-game' src={Gameimg}/></p>
                                    <p className='mb-auto'><span className='fw-bold goat-title-name goat-total-new-heading'>Kathecommiskeyj</span><br /><span className='text-muted goat-total-sub-heading py-2'>14,463,711,370 $BANI
                                    </span><br />
                                        <span className='text-muted goat-total-sub-heading'>Reward: 2 $TON
</span></p>
                                </div>
                                <div>
                                    <p className='mb-auto'>
                                        <img alt="" style={{ width: "50px" }} src={Bronze} />
                                    </p>
                                </div>
                            </div>
                            <div className=' d-flex align-items-center py-3 justify-content-between'>
                                <div className='d-flex align-items-center'>
                                <p className='goat-us-dollars mb-0'><img className='diamong-game' src={Gameimg}/></p>
                                    <p className='mb-auto'><span className='fw-bold goat-title-name goat-total-new-heading'>Marshanfot</span><br /><span className='text-muted py-2 goat-total-sub-heading'>14,245,049,121 $BANI</span><br />
                                        <span className='text-muted goat-total-sub-heading'>Reward: 1 $TON</span></p>
                                </div>
                                <div>
                                    <p className='mb-auto fw-bold goat-new-numbers'>
                                        #4
                                    </p>
                                </div>
                            </div>
                            <div className=' d-flex align-items-center py-3 justify-content-between'>
                                <div className='d-flex align-items-center'>
                                <p className='goat-us-dollars mb-0'><img className='diamong-game' src={Gameimg}/></p>
                                    <p className='mb-auto'><span className='fw-bold goat-title-name goat-total-new-heading'>7328909317</span><br /><span className='text-muted py-2 goat-total-sub-heading'>14,020,737,583 $BANI</span><br />
                                        <span className='text-muted goat-total-sub-heading'>Reward: 1 $TON</span></p>
                                </div>
                                <div>
                                    <p className='mb-auto fw-bold goat-new-numbers'>
                                        #5
                                    </p>
                                </div>
                            </div>
                            <div className=' d-flex align-items-center py-3 justify-content-between'>
                                <div className='d-flex align-items-center'>
                                <p className='goat-us-dollars mb-0'><img className='diamong-game' src={Gameimg}/></p>
                                    <p className='mb-auto'><span className='fw-bold goat-title-name goat-total-new-heading'>US
                                    Marvinpetzoldt</span><br /><span className='text-muted py-2 goat-total-new-heading'>13,997,107,630 $BANI</span><br />
                                        <span className='text-muted goat-total-sub-heading'>Reward: 1 $TON</span></p>
                                </div>
                                <div>
                                    <p className='mb-auto fw-bold goat-new-numbers'>
                                        #6
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  );
}

export default Navtabs;
