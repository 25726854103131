import React from 'react'
import { AiOutlineArrowLeft } from "react-icons/ai";
import { ImNotification } from "react-icons/im";
import { Link } from 'react-router-dom';
function Catching() {
    return (
        <div className='min-vh-100 goat-full-home goat-full-box-02'>
            <div className='container-fluid'>
                <div className='row px-0'>
                    <div className='col-12'>
                        <div className='d-flex justify-content-between align-items-center py-2'>
                            <Link to="/">
                                <AiOutlineArrowLeft className='text-white fs-4' />
                            </Link>
                            <p className='mb-auto  text-white fw-bold'>Catch Your Goats</p>
                            <ImNotification className='text-white fs-4' />
                        </div>
                    </div>
                </div>
                <p className='text-muted fw-bold'>Find the $GOATS treasures to multiply your $GOATS</p>
                <div className='row'>
                    <div className='col-10 mx-auto'>
                        <div>
                            <div>
                            <input type="text"/>
                            </div>
                            <div>
                            <input type="text"/>
                            </div>
                            <div>
                            <input type="text"/>
                            </div>
                            <div>
                            <input type="text"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Catching