import React, { useCallback, useEffect, useState } from "react";
import "../App.css";
import { FaArrowUp } from "react-icons/fa6";
import Fire from "../asset/images/fire-emoji.gif";
import Homebanner from "../asset/images/current-banner.jpg"
import Homecalendar from "../asset/images/homecalendar.gif"
import Footer from "./Footer";
import Handshake from "../asset/images/handshake.gif"
import Joystick from "../asset/images/joystickses.png"
import Bani from "../asset/images/BANI.png"
import Trone from "../asset/images/troneimg.png"
import { useAdsgram } from "./useAdsgram";
import Coinpage from "../asset/images/coin.png"

import {
  TonConnect,
  TonConnectButton,
  useTonAddress,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import { Link } from "react-router-dom";
import { makeApiRequest } from "../services/commonAxiosService";
import { ToastContainer, toast } from "react-toastify";
function Firsttab({ data }) {
  let chatId;
  if (data) {
    chatId = data
  } else {
    chatId = localStorage.getItem('userId')
  }
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const wallet = useTonWallet();
  const [taskData, setTaskData] = useState([]);
  const [userData, setUserData] = useState({})
  const [timer, setTimer] = useState({})

  useEffect(() => {
    
    const intervalId = setInterval(() => {
     
      const expiryTime = () => {
        try {
          getUserData()
          const endDate = new Date(userData.userEndDate);

          const now = new Date();
          const remainingMs = endDate - now;

          const countdownSeconds = Math.floor(remainingMs / 1000);
          const hours = Math.floor(countdownSeconds / 3600);
          const minutes = Math.floor((countdownSeconds % 3600) / 60);
          const seconds = countdownSeconds % 60;

          const countdown = {
            hours: Math.max(hours, 0),
            minutes: Math.max(minutes, 0),
            seconds: Math.max(seconds, 0),
          };

          setTimer(countdown);
          if (remainingMs <= 0) clearInterval(intervalId);

        } catch (error) {
          console.error("Error calculating expiry time:", error);
          clearInterval(intervalId);
        }
      };

      expiryTime();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [userData]);


  useEffect(() => {
    console.log('chatId---', chatId)
    viewTasks(chatId)
    
    getUserData();
    const updateUserAddress = async () => {
      let payLoad = {
        userChatId: chatId,
        address: wallet.account.address,
        publicKey: wallet.account.publicKey,
      };
      let params = {
        url: "update-user-address",
        method: "POST",
        data: payLoad,
      };

      let response = await makeApiRequest(params);
      if (response.status) {
        toast.success(response.message, { theme: "dark" });
      }
    };

    if (wallet !== null) {
      updateUserAddress();
    }
  }, [wallet]);
  const getUserData = async () => {
    try {
      let payload = {
        userChatId: chatId
      }
      let params = {
        url: "get-user-data",
        method: "post",
        data: payload
      }
      let response = await makeApiRequest(params)
      if (response.status) {
        setUserData(response.data)
      }
    } catch (error) {
      console.log(error, "something went wrong");
    }
  }

  const handleSendTransaction = async () => {

    try {
      if (!tonConnectUI.connected) {
        alert("Please connect your wallet first.");
        return;
      }

      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 60,
        messages: [
          {
            address: "0QC2QBdgYvpCq1JQL5ODn34PZWEJSQprDxy8fhbmATzFl8oX",
            amount: "100000000",
          },
        ],
      };

      const result = await tonConnectUI.sendTransaction(transaction);
      alert(`Transaction successful!`);
    } catch (error) {
      console.error("Transaction Failed:", error);
      alert(`Transaction failed: ${error.message}`);
    }
  };

  const viewTasks = async (UserId) => {
    let data = {
      userId: UserId,
      taskType: "overall"
    }
    let params = {
      url: "view-tasks",
      method: "POST",
      data
    };
    let response = await makeApiRequest(params);
    if (response.status) {
      setTaskData(response.data);
    }
  };

  const doTaskSubmission = async (taskId) => {
    try {
      let data = {
        userId: chatId,
        taksId: taskId
      }
      let payLoad = {
        url: "do-task",
        method: "POST",
        data
      }
      let taskRes = await makeApiRequest(payLoad);
      if (taskRes.status) {
        toast.success(taskRes.message, { theme: "dark" });
        viewTasks(chatId)
      }
    } catch (error) {
      console.log("doTaskSubmission error", error)
    }
  }
  const filteredTasks = taskData.filter(data => data.taskTitle === "connect Wallet");
  const walletConnectionReward = async (taskId) => {
    try {

      if (userFriendlyAddress) {
        doTaskSubmission(taskId)
      } else {
        var element = document.getElementById("walletConnect");
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        toast.error("Connect your wallet", { theme: "dark" });
      }
    } catch (error) {
      console.log("walletConnectionReward error", error)
    }
  }

  const onReward = useCallback(() => {
    alert('Reward');
  }, []);
  const onError = useCallback((result) => {
    alert(JSON.stringify(result, null, 4));
  }, []);

  /**
   * insert your-block-id
   */
  const showAd = useAdsgram({ blockId: "5060", onReward, onError });

  return (
    <>
      <ToastContainer />
      <div className="goat-full-home " style={{ backgroundColor: "black" }}>
        <div className="container-fluid px-0">
          <div className="row ">
            <div className="col-sm-12   px-0 mx-auto goat-full-box-02">
              <div className="goat-full-box-01">
                <div className="row ">
                  <div className="col-12 ">
                    <div className="goat-whl-ctn-01 px-0 mx-0">
                      <div className="position-relative">
                        <img
                          alt=""
                          src={Homebanner}
                          className="goat-top-img-01 mx-0"
                        />
                        {/* <div className="d-flex whl-home-us-btns justify-content-around align-items-center px-1">
                          {
                            userData && userData.userProfile == '' ? (
                              <>
                                <p className="home-us-btns mb-auto d-flex justify-content-center align-items-center">{userData.username.slice(0, 1)}</p>
                              </>
                            ) : (
                              <>
                              <div>
                                <img src={userData.userProfile} className="Frontimage-01"/>
                              </div>
                              </>
                            )
                          }
                          <button className="mx-1 px-3 py-2 daily-check-in-btn mt-0 d-flex justify-content-between align-items-center" style={{ width: "70%" }}>
                            
                            <span className="text-white daily-check-in-ctn fw-bold  d-flex align-items-center">
                              <svg viewBox="0 0 16 16" focusable="false" class="chakra-icon shopping-cart-01 css-kawbk9" xmlns="http://www.w3.org/2000/svg"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.0833 4.4875H10.0417V3.72917C10.0417 3.55658 9.90176 3.41667 9.72917 3.41667C9.55658 3.41667 9.41667 3.55658 9.41667 3.72917V4.4875H6.58333V3.72917C6.58333 3.55658 6.44342 3.41667 6.27083 3.41667C6.09824 3.41667 5.95833 3.55658 5.95833 3.72917V4.4875H5.91667C4.76607 4.4875 3.83333 5.42024 3.83333 6.57083V10.3208C3.83333 10.8734 4.05283 11.4033 4.44353 11.794C4.83423 12.1847 5.36413 12.4042 5.91667 12.4042H10.0833C11.2339 12.4042 12.1667 11.4714 12.1667 10.3208V6.57083C12.1667 5.42024 11.2339 4.4875 10.0833 4.4875ZM10.1875 6.7125C10.1875 6.88509 10.0476 7.025 9.875 7.025H6.125C5.95241 7.025 5.8125 6.88509 5.8125 6.7125C5.8125 6.53991 5.95241 6.4 6.125 6.4H9.875C10.0476 6.4 10.1875 6.53991 10.1875 6.7125Z" fill="white"></path></svg></svg>
                              &nbsp;Daily check-in</span>
                            <span className="">
                              <IoRemoveOutline className="red-line-convertor me-0" style={{ zIndex: 2 }} />
                              <IoRemoveOutline className="red-line-convertor me-0" style={{ zIndex: 2 }} />
                              <IoRemoveOutline className="red-line-convertor me-0" style={{ zIndex: 2 }} />
                              <IoRemoveOutline className="red-line-convertor me-0" style={{ zIndex: 2 }} />
                              <IoRemoveOutline className="red-line-convertor me-0" style={{ zIndex: 2 }} />
                            </span></button>
                          <Link to="/modalspage" className="home-us-btns-01 mb-auto  d-flex justify-content-center align-items-center">
                            <svg viewBox="0 0 32 32" focusable="false" data-bs-toggle="modal" data-bs-target="#fourpointsModal" class="chakra-icon css-1e4zlp5" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_b_2099_2307)"><rect width="32" height="32" rx="16" fill="black" fill-opacity="0.6"></rect><path d="M15 12.6666C15 13.9553 13.9553 15 12.6667 15C11.378 15 10.3333 13.9553 10.3333 12.6666C10.3333 11.378 11.378 10.3333 12.6667 10.3333C13.9553 10.3333 15 11.378 15 12.6666Z" fill="white"></path><path d="M21.6667 12.6666C21.6667 13.9553 20.622 15 19.3333 15C18.0447 15 17 13.9553 17 12.6666C17 11.378 18.0447 10.3333 19.3333 10.3333C20.622 10.3333 21.6667 11.378 21.6667 12.6666Z" fill="white"></path><path d="M15 19.3333C15 20.622 13.9553 21.6666 12.6667 21.6666C11.378 21.6666 10.3333 20.622 10.3333 19.3333C10.3333 18.0446 11.378 17 12.6667 17C13.9553 17 15 18.0446 15 19.3333Z" fill="white"></path><path d="M21.6667 19.3333C21.6667 20.622 20.622 21.6666 19.3333 21.6666C18.0447 21.6666 17 20.622 17 19.3333C17 18.0446 18.0447 17 19.3333 17C20.622 17 21.6667 18.0446 21.6667 19.3333Z" fill="white"></path></g><defs><filter id="filter0_b_2099_2307" x="-40" y="-40" width="112" height="112" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feGaussianBlur in="BackgroundImageFix" stdDeviation="20"></feGaussianBlur><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2099_2307"></feComposite><feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2099_2307" result="shape"></feBlend></filter></defs></svg>
                          </Link>
                        </div> */}
                      </div>
                      <div className="goat-whl-pts-num goat-ctn-box-01 py-2" id="walletConnect">
                        <div className="  d-flex justify-content-between align-items-center px-2 ">
                          <h4 className="mb-auto">
                            <span className="goat-num-800 text-white">{userData.balance}</span>
                            <br />
                            <span className=" gots-dollar-time fw-bold">
                              $BANI
                            </span>
                          </h4>
                          <div className="goat-pic-01 text-end">
                            <img
                              class="goat-pic-001"
                              src={Bani}
                            />
                          </div>

                        </div>
                        <div className="text-center my-3" >
                          <TonConnectButton className="goat-connect-wallet" />
                          {userFriendlyAddress && (
                            <div>
                              <button
                                onClick={handleSendTransaction}
                                className="py-2 fw-bold goat-connect-wallet-btn"
                              >
                                <span className="me-2">
                                  <svg
                                    viewBox="0 0 18 18"
                                    focusable="false"
                                    class="chakra-icon goat-home-wallet goat-wallet-img css-bki6px"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M16.5 11.8636C16.5 13.7464 14.9737 15.2727 13.0909 15.2727H4.90909C3.0263 15.2727 1.5 13.7464 1.5 11.8636V6.40909C1.5 4.5263 3.0263 3 4.90909 3H13.0909C14.9737 3 16.5 4.5263 16.5 6.40909V11.8636ZM16.5 9.96136V8.31136H13.0909C12.6353 8.31136 12.2659 8.68073 12.2659 9.13636C12.2659 9.592 12.6353 9.96136 13.0909 9.96136H16.5Z"
                                    ></path>
                                  </svg>
                                </span>{" "}
                                Transaction
                              </button>
                            </div>
                          )}
                          <p
                            className="text-start px-2 mt-2 fw-bold gots-dollar-time"
                            style={{ color: "grey" }}
                          >
                            Balance
                          </p>
                          <div className="text-start px-2">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex">
                                <span
                                  className="goatnum-02 d-flex align-items-center justify-content-center"
                                  style={{
                                    width: "50px",
                                    borderRadius: "50%",
                                    marginRight: "10px",
                                  }}
                                >
                                  {/* <svg
                                    viewBox="0 0 40 40"
                                    focusable="false"
                                    class="chakra-icon css-4dpsp1"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_1332_1997)">
                                      <path
                                        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95429 31.0457 0 20 0C8.95429 0 0 8.95429 0 20C0 31.0457 8.95429 40 20 40Z"
                                        fill="#0098EA"
                                      ></path>
                                      <path
                                        d="M26.8288 11.1629H13.1704C10.6591 11.1629 9.06743 13.8719 10.3309 16.0618L18.7603 30.6724C19.3104 31.6264 20.6889 31.6264 21.2389 30.6724L29.6701 16.0618C30.9318 13.8754 29.3401 11.1629 26.8305 11.1629H26.8288ZM18.7534 26.2909L16.9176 22.7379L12.4881 14.8156C12.1959 14.3085 12.5568 13.6587 13.1687 13.6587H18.7517V26.2926L18.7534 26.2909ZM27.5077 14.8139L23.0799 22.7396L21.2441 26.2909V13.657H26.8271C27.439 13.657 27.7999 14.3068 27.5077 14.8139Z"
                                        fill="white"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1332_1997">
                                        <rect
                                          width="40"
                                          height="40"
                                          fill="white"
                                        ></rect>
                                      </clipPath>
                                    </defs>
                                  </svg> */}
                                  <img src={Trone} />
                                </span>
                                <span className="fw-bold text-white">
                                  $TON
                                  <br />
                                  <span
                                    className="text-white"
                                    style={{ fontSize: "13px" }}
                                  >
                                    ---$TON
                                  </span>
                                </span>
                              </div>
                              <button
                                style={{ width: "50px", height: "50px" }}
                                className="goatnum-03"
                              >
                                <FaArrowUp className="fw-bold" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="goat-cards-01 px-0 mx-0">
                      <div class="card goats-whl-card">
                        <div className="goats-fortune-fetch-img-00">
                          <img
                            alt=""
                            src="https://dev.goatsbot.xyz/images/home-banner.webp?w=3840&amp;q=75"
                            className="goats-fortune-fetch-img-01 mx-0"
                          />
                        </div>
                        <div class="card-body px-1">
                          <div>
                            <p
                              class="card-text goat-cup-ctn mb-auto"
                              style={{ fontSize: "12px", color: "GrayText" }}
                            >
                              🏆 Jackpot: 20 $TON | 1st Prize: 10 $TON | 2nd
                              Prize: 7 $TON | 3rd Prize: 3 $TON | 100x
                              Consolation Prizes: 0.1 $TON each
                            </p>
                            <p
                              className="mb-auto"
                              style={{ fontSize: "12px", color: "GrayText" }}
                            >
                              📜 Complete all the missions below{" "}
                            </p>
                            <p
                              className="mb-auto"
                              style={{ fontSize: "12px", color: "GrayText" }}
                            >
                              📜 Own at least 20K GOATS.
                            </p>
                          </div>
                          <hr className="text-white"></hr>
                          {filteredTasks.map((data, index) => (
                            <>
                          <div className="d-flex my-4 align-items-center justify-content-between">
                            <span>
                              <img
                                src="https://goats-bot.s3.amazonaws.com/avatars/wallet.png.png?w=3840&amp;q=75"
                                className="goat-clr-wallet"
                              />
                            </span>
                            <span
                              className="text-white"
                              style={{
                                fontSize: "16px",
                                position: "relative",
                                left: "-20px",
                              }}
                            >
                             {data.taskTitle}
                            </span>
                            {
                                      data.userTaskData.length ? (
                                        <>
                                          <span className="goat-check-01">
                                            <BsCheckLg
                                              className="text-white"
                                              style={{ fontSize: "20px" }}
                                            />
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <a onClick={()=>walletConnectionReward(data._id)} className='goats-do-btn'>DO</a>
                                        </>
                                      )
                                    }
                          </div>
                            </>
                          ))}
                          {taskData.length > 0 ? (
                            taskData
                            .filter(data => data.taskTitle !== "connect Wallet")
                            .map((data,index) => {
                              return (
                                <>
                                  <div className="row px-0 py-3 d-flex align-items-center position-relative">
                              <div className="col-2 px-0 ">
                              <span>
                                      <img
                                        src="https://goats-bot.s3.amazonaws.com/avatars/Goats_Round.png.png?w=3840&amp;q=75"
                                        className="goat-clr-wallet"
                                      />
                                    </span>
                                    <span>
                                      <img
                                        className="goat-cross-over-01"
                                        src="https://dev.goatsbot.xyz/icons/x.png?w=3840&amp;q=75"
                                      />
                                      
                                    </span>
                              </div>
                              <div className="col-8 px-0 ">
                              <span
                                      className="text-white"
                                      style={{
                                        fontSize: "16px",
                                        position: "relative",
                                       
                                      }}
                                    >
                                      {data.taskTitle}

                                      <div className="empty-div">
                                      <p className="mb-auto "style={{fontSize: "13px"}}>firstone</p>
                                      </div>
                                      

                                      <p class="text-muted fw-bold total-small-size-ctn mb-auto">+3,000 BANI</p>
                                    </span>
                              </div>
                              <div className="col-2 px-0 ">
                              {
                                      data.userTaskData.length ? (
                                        <>
                                         <span className="goat-check-01">
                                            <BsCheckLg
                                              className="text-white"
                                              style={{ fontSize: "20px" }}
                                            />
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                        <button onClick={()=>{doTaskSubmission(data._id)}} className='goats-do-btn'>DO</button>
                                        </>
                                      )
                                    }
                              </div>
                             </div>
                                </>
                              );
                            })
                          ) : (

                            <>
                             
                              <h2>There is no tasks</h2>
                            </>
                          )}

                              <div className="empty-div">
                              <div className="d-flex my-4 align-items-center position-relative justify-content-between">
                            <span>
                              <img
                                src="https://goats-bot.s3.amazonaws.com/avatars/Goats_Round.png.png?w=3840&amp;q=75"
                                className="goat-clr-wallet"
                              />
                            </span>
                            <span>
                              <img
                                className="goat-cross-over-01"
                                src="https://dev.goatsbot.xyz/icons/x.png?w=3840&amp;q=75"
                              />
                            </span>
                            <span
                              className="text-white"
                              style={{
                                fontSize: "16px",
                                position: "relative",
                                left: "-32px",
                              }}
                            >
                              Comment your
                              <br /> TON wallet
                            </span>
                            <span className="goat-check-01">
                              <BsCheckLg
                                className="text-white"
                                style={{ fontSize: "20px" }}
                              />
                            </span>
                          </div>
                              </div>
                         


                          <div className="d-flex justify-content-between g-3">
                            <p className="mb-auto goat-participant-box p-1">
                              participants
                              <br />
                              <span className="text-white">6,098,789</span>
                            </p>
                            <button className="goat-participate-btns py-2 mt-0">
                              Participate
                            </button>
                          </div>
                          <p className="mt-3" style={{ color: "grey" }}>
                            *Results will be announced at 4AM UTC Sep 10th
                          </p>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div> */}

                    <div className="goat-cards-01 mt-2  py-3 px-0 mx-0">

                      <p className="fw-bold goat-special-mission">special</p>

                      <div className="d-flex">
                        {/* <p>
                          <img src={Coinpage} className="coin-img-points"/>
                        </p> */}
                        <Link
                          style={{ textDecoration: "none" }}
                          to="/jackpotpage"
                          className="goat-jackpots me-3 p-2 bg-dark position-relative"
                        >
                          <img
                            className="goat-jackpot-emoji mb-4"
                            src={Homecalendar}
                          />
                           {/* <p>
                          <img src={Coinpage} className="coin-img-points"/>
                        </p>  */}
                          <p
                            className="mb-0 fw-bold text-white mb-2"
                            style={{ color: "black" }}
                          >
                            Daily
                            <br />
                            rewards
                          </p>
                          {(timer.hours || timer.minutes || timer.seconds) ? (
                            <div className="timer text-white">
                              <span className="timer-button rewards-hours">{timer.hours || '00'}H</span>:
                              <span className="timer-button rewards-hours">{timer.minutes || '00'}M</span>:
                              <span className="timer-button rewards-hours">{timer.seconds || '00'}S</span>
                            </div>
                          ) : (
                            <></>
                          )}

                        </Link>

                        <Link
                          style={{ textDecoration: "none" }}
                          to="/slotspage"
                          className="goat-jackpots me-3 p-2 bg-dark "
                        >
                          <img
                            className="goat-jackpot-emoji mb-4"
                            style={{ objectFit: "contain" }}
                            src={Joystick}
                          />
                          <p
                            className="mb-0 fw-bold text-white"
                            style={{ color: "black" }}
                          >
                            Play Mini
                            <br />
                            Games
                          </p>
                        </Link>

                        {/* <div className="goat-jackpots me-3 p-2">
                          <img
                            className="goat-jackpot-emoji mb-4"
                            src={slots}
                          />
                          <p
                            className="mb-0 fw-bold"
                            style={{ color: "black" }}
                          >
                            GOATS
                            <br />
                            slots
                          </p>
                        </div> */}

                        {/* <button onClick={showAd}>Show Ad</button> */}

                      </div>
                    </div>


                    {/* <div className="goat-cards-01 mt-2  py-0 px-0 mx-0">
                      <div className="goat-swiper-class">
                        <Swiper />
                      </div>
                    </div> */}


                    <div className="px-2 goat-cards-01 py-3 px-0 mx-0">
                      <div class="my-4">
                        <p class="goat-special-mission fw-bold">Your rewards</p>
                        <div class="d-flex justify-content-between align-items-center bg-dark px-2 py-3 goat-boxex-tab">
                          <div class="d-flex ">
                            <div class="positio-relative">
                              <img alt="" class="alt-goat-tabs" src={Fire} />
                            </div>

                            <div>
                              <p class="mb-auto">
                                <span class="text-white mb-5 goats-earn-01 fw-bold">
                                  Welcome Rewards
                                </span>
                                <br />
                                <span class=" fw-bold" style={{color:"grey"}}>
                                  +888 $BANI
                                </span>
                              </p>
                            </div>

                          </div>

                          <div>
                            {/* <button class="goats-do-btn fw-bold"></button> */}
                          </div>
                        </div>
                        <div class="d-flex justify-content-between mt-3 align-items-center bg-dark px-2 py-3 goat-boxex-tab">
                          <div class="d-flex ">
                            <div class="positio-relative">
                              <img alt="" class="alt-goat-tabs" src={Handshake} />
                            </div>

                            <div>
                              <p class="mb-auto">
                                <span class="text-white mb-5 goats-earn-01 fw-bold">
                                  Invite friends
                                </span>
                                <br />
                                <span class=" fw-bold" style={{color:"grey"}}>
                                  +222 $BANI
                                </span>
                              </p>
                            </div>

                          </div>

                          <div>
                            {/* <button class="goats-do-btn fw-bold"></button> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Firsttab;
