import React from 'react'
import { MdOutlineCancel } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";
import Jackpot from "../asset/images/jackpot-emoji.gif";
import slots from "../asset/images/slots-img.gif";
// import Lotteryone from "../asset/images/lotteryone.webp";
// import Lotterytwo from "../asset/images/lotterytwo.webp";
// import Lotterythree from "../asset/images/lotterythree.webp";
// import Lotteryfour from "../asset/images/lotteryfour.webp";
import calendars from "../asset/images/calendars.gif";
function Modalspage() {
    return (
        <div className='goat-full-box-02 min-vh-100 goat-full-home'>
            <div className='container-fluid'>
                <p className='text-end py-3'>
                    <Link to="/"><MdOutlineCancel className='OutlineCancel-area' /></Link>
                </p>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div>
                            <p className='text-white hub-head-ctn fw-bold'>Hub</p>
                            <div class="input-group mb-3 bg-dark" style={{ borderRadius: "20px" }}>
                                <span class="input-group-text bg-transparent border-0 text-white" id="basic-addon1"><IoSearch /></span>
                                <input type="text" class="form-control bg-transparent py-2 text-muted fw-bold border-0" style={{ boxShadow: "none" }} placeholder="search" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row  py-2'>
                    <p className='text-muted fw-bold'>special</p>
                    <div className='col-3 text-center'>
                        <div>
                            <Link to="/jackpotpage">
                            <img
                                className="goat-jackpot-emoji-01"
                                src={Jackpot}
                            />
                            </Link>
                            
                              <p className="text-center mt-2 fw-bold text-muted alter-lottery-slots">lottery</p>
                        </div>
                    </div>
                    <div className='col-3 text-center ms-0'>
                        <div>
                          <Link to="/slotspage">
                          <img
                                className="goat-jackpot-emoji-01"
                                src={slots}
                            />
                          </Link>  
                              <p className="text-center mt-2 fw-bold text-muted alter-lottery-slots">lottery</p>
                        </div>
                    </div>
                   
                </div>
                <div className='row  py-2'>
                    <p className='text-muted fw-bold'>special</p>
                    <div className='col-3 text-center'>
                        <div>
                           {/* <Link to="/flipping" style={{textDecoration:"none"}}><img
                                className="goat-jackpot-emoji-001"
                                src={Lotteryone}
                            />
                            </Link>  */}
                              <Link to="/flipping" style={{textDecoration:"none"}} className="text-center mt-2 fw-bold text-muted alter-lottery-slots">Flipping</Link>
                        </div>
                    </div>
                    <div className='col-3 text-center ms-0'>
                        <div>
                            {/* <Link to="/catching" style={{textDecoration:"none"}}>
                            <img
                                className="goat-jackpot-emoji-001"
                                src={Lotterytwo}
                            />
                            </Link> */}
                          
                              <Link to="/catching" style={{textDecoration:"none"}} className="text-center mt-2 fw-bold text-muted  alter-lottery-slots">Catching</Link>
                        </div>
                    </div>
                    {/* <div className='col-3 text-center ms-0'>
                        <div>
                            <img
                                className="goat-jackpot-emoji-001"
                                src={Lotterythree}
                            />
                              <p className="text-center mt-2 fw-bold text-muted alter-lottery-slots">Dicing</p>
                        </div>
                    </div>
                    <div className='col-3 text-center ms-0'>
                        <div>
                            <img
                                className="goat-jackpot-emoji-001"
                                src={Lotteryfour}
                            />
                              <p className="text-center mt-2 fw-bold text-muted alter-lottery-slots">Spinning</p>
                        </div>
                    </div> */}
                </div>
                <div className='row  py-2'>
                    <p className='text-muted fw-bold'>Others</p>
                    <div className='col-3 text-center'>
                        <div>
                            <img
                                className="goat-jackpot-calendar"
                                src={calendars}
                            />
                              <p className="text-center mt-2 fw-bold text-muted alter-lottery-slots">Daily-check in</p>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default Modalspage