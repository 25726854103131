import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import ShowAds from "./pages/showads";
import Ranking from "./pages/Ranking";
import Mission from "./pages/Mission";
import Friends from "./pages/Friends";
import Footer from "./pages/Footer";
import Previousnumber from "./pages/Previousnumber";
import Jackpotpage from "./pages/Jackpotpage";
import Slotspage from "./pages/Slotspage";
import Flipping from "./pages/Flipping";
import Catching from "./pages/Catching";
// import Snake from "./pages/Snake";
import ScrollToTop from "./pages/ScrollToTop"
import Fruitcutter from "./pages/Fruitscutter";
import Coinflipping from "./pages/Coinflipping";
import Fruitcard from "./pages/Fruitcard";
import Banivideos from "./pages/Banivideos"
// import FruitNinjaGame from "./pages/FruitNinjaGame";
// import Angrybird from "./pages/Angrybird";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import Modalspage from "./pages/Modalspage";
import 'react-toastify/dist/ReactToastify.css';
// import Gaming from "../src/pages/Gaming"

const manifestUrl = `${window.location.origin}/tonconnect-manifest.json`;

const MainApp = () => {
  // Get the current route using `useLocation` hook
  const location = useLocation();

  // Function to check if the current route is "/coinflipping" or "/fruitcutter"
  const shouldShowFooter = location.pathname !== "/coinflipping" && location.pathname !== "/fruitcutter" && location.pathname !== "/fruitcard" && location.pathname !== "/banivideos";

  return (
    <div className="App">
      <ScrollToTop/>
      <Routes>
        
        <Route path="/" element={<Home />} />
        <Route path="/ranking" element={<Ranking />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/friends" element={<Friends />} />

        <Route path="/ad" element={<ShowAds />} />

        <Route path="/jackpotpage" element={<Jackpotpage />} />
        <Route path="/slotspage" element={<Slotspage />} />
        <Route path="/previousnumber" element={<Previousnumber />} />
        <Route path="/modalspage" element={<Modalspage />} />
        <Route path="/flipping" element={<Flipping />} />
        <Route path="/catching" element={<Catching />} />
        {/* <Route path="/snake" element={<Snake />} /> */}
        <Route path="/fruitcutter" element={<Fruitcutter />} />
        <Route path="/coinflipping" element={<Coinflipping />} />
        <Route path="/fruitcard" element={<Fruitcard />} />
        {/* <Route path="/banivideos" element={<Banivideos />} /> */}
        {/* <Route path="/gaming" element={<Gaming />} /> */}
    
        {/* <Route path="/angrybird" element={<Angrybird />} /> */}
      </Routes>
      
      {/* Conditionally render Footer, hide for Coinflipping and Fruitcutter */}
      {shouldShowFooter && <Footer />}
    </div>
  );
};

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const chatIdParam = urlParams.get("chat_id");

  if (chatIdParam) {
    localStorage.setItem('userId', chatIdParam);
  }

  return (
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <BrowserRouter>
        <MainApp />
      </BrowserRouter>
    </TonConnectUIProvider>
  );
};

export default App;
