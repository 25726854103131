import { useCallback, ReactElement } from 'react'
import { useAdsgram } from "./useAdsgram";


 function ShowAds() {
  const onReward = useCallback(() => {
    alert('Reward');
  }, []);
  const onError = useCallback((result) => {
    alert(JSON.stringify(result, null, 4));
  }, []);

  /**
   * insert your-block-id
   */
  const showAd = useAdsgram({ blockId: "4017", onReward, onError });

  return (
    <button onClick={showAd}>Show Ad</button>
  )
}

export default ShowAds;