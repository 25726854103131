import React, { useState } from 'react';
import '../asset/css/coinflipping.css';
import { FaCaretSquareLeft } from "react-icons/fa";
import { Link } from 'react-router-dom';
const CoinFlip = () => {
  const [headsCount, setHeadsCount] = useState(0);
  const [tailsCount, setTailsCount] = useState(0);
  const [status, setStatus] = useState('');
  const [flipClass, setFlipClass] = useState('');

  const deferFn = (callback, ms) => {
    setTimeout(callback, ms);

  };

  const processResult = (result) => {
    if (result === 'heads') {
      setHeadsCount((prev) => prev + 1);
    } else {
      setTailsCount((prev) => prev + 1);
    }
    setStatus(result.toUpperCase());
  };

  const flipCoin = () => {
    setFlipClass(''); // Reset flip class for animation
    const random = Math.random();
    const result = random < 0.5 ? 'heads' : 'tails';
    
    deferFn(() => {
      setFlipClass(`animate-${result}`);
      deferFn(() => processResult(result), 2900);
    }, 100);
  };

  return (
    <div className='total-coin-flipping bg-dark min-vh-100 d-flex justify-content-center align-items-center'>
      <div className="container">
        <div id="coin" className={flipClass}>
          <div className="heads"></div>
          <div className="tails"></div>
          
        </div>
        <button id="flip" className='fw-bold' onClick={flipCoin} style={{border:0,borderRadius:"5px",color:"white"}}>Flip this thing</button>
        
        {/* Display chosen side */}
        {/* {status && (
          <p className='text-white mt-4 tot-choosen-coins'>
            You chose {status === 'HEADS' ? 'Heads' : 'Tails'}
          </p>
        )} */}

        <p className='mt-4 rotational-coins fw-bold'>
          Heads: <span id="headsCount">{headsCount}</span> Tails: <span id="tailsCount">{tailsCount}</span>
        </p>

        {/* Display the result status */}
        <p>
          <span id="status">{status}</span>
        </p>
        <Link to="/">
        <FaCaretSquareLeft className='coinflipping-left-icon' style={{color:"#fbb916"}}/>
        </Link>
      </div>
    </div>
  );
};

export default CoinFlip;
