let config = {}
if(process.env.NODE_ENV == "development"){
    config = {
        backendurl : "http://localhost:4000/user/",
        botName : 'TaskRewardBot_bot'
    }
}
else{
    //=====Live=========//
    config = {
        backendurl : "https://api.banicoin.io/user/",
        botName : 'Realbani_bot'
    }
    //=====Staging=========//
    // config = {
    //     backendurl : "https://staging.api.banicoin.io/user/",
    //     botName : 'TaskRewardBot_bot'
    // }
}

module.exports = config